import $ from 'jquery';

/* COMPONENTS IMPORT */
import './components/carousel';
import './components/navigation-menu';


// My scripts
$('#videoTabList .nav-link').on('click', function(event) {
    $('#videoTabList .nav-link').removeClass('active');
    event.preventDefault()
    $(this).tab('show')
});


// Sound/Mute video
$('.hero__video').closest('.hero').append('<a href="#" id="heroBtnVolume"><i class="fas fa-volume-mute"></i></a>')
$('#heroBtnVolume').on('click', function(e){
    e.preventDefault();
    if($(this).hasClass('active')){
        $(this).removeClass('active');
        $(this).children('i').removeClass('fa-volume-up').addClass('fa-volume-mute');
        $('.hero__video').prop('muted', true)
    } else {
        $(this).addClass('active')
        $(this).children('i').removeClass('fa-volume-mute').addClass('fa-volume-up');
        $('.hero__video').prop('muted', false)
    }
})