import $ from 'jquery';

// Open big menu
$('#navigationMenu .nav-link').on('click', function(){
    $('body').addClass('overflow-hidden');
    $('#navigationMenuContent').css('display', 'block');
    $('#logo').css('display', 'block');
    $('#btnCloseNavigationMenu').css('display', 'block');
    if($(window).width() < 992) {
        $('.nav-tabs').addClass('nav-tabs--mobile')
    };
});
// Close big menu
$('#btnCloseNavigationMenu').on('click', function(){
    $('body').removeClass('overflow-hidden');
    $('#navigationMenuContent').css('display', 'none');
    $('#logo').css('display', 'none');
    $('#btnCloseNavigationMenu').css('display', 'none');
    $('#navigationMenu .nav-link').removeClass('active');
    $('.nav-tabs').removeClass('nav-tabs--mobile')
});