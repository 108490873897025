// https://swiperjs.com/
import Swiper, {Navigation, Pagination, Thumbs, Scrollbar} from 'swiper';

// Configure Swiper to use modules
Swiper.use([Navigation, Pagination, Thumbs, Scrollbar]);


// Auto slide
const swiperAuto = new Swiper('.swiper-container--auto', {
    slidesPerView: "auto",
    spaceBetween: 16,
});


// Thumbnail slide
const swiperThumb = new Swiper('.swiper-container--thumb', {
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    grabCursor: true,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
});
// Single slide
const swiperSingle = new Swiper('.swiper-container--single', {
    spaceBetween: 10,
    // loop: true,
    grabCursor: true,
    thumbs: {
        swiper: swiperThumb,
    },
});

// Single slide auto
const swiperSingleAuto = new Swiper('.swiper-container--single-auto', {
    spaceBetween: 15,
    slidesPerView: "auto",
    loop: true,
    grabCursor: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        992: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            grabCursor: false,
        },
    },
});

// Single slide mobile
const swiperSingleMobile = new Swiper('.swiper-container--single-mobile', {
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 15,
    scrollbar: {
        el: ".swiper-scrollbar",
        draggable: true,
    },
});